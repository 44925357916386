import Axios from 'axios';
import moment from 'moment';

export default {
  data() {
    return {
      TOKEN: this.$store.state.token,
      me: this.$store.state.me,
      hiring: {
        company: {},
      },
      provider: {
        name: '',
        avatar: '',
      },
      company: {},
      conversation: {
        messages: [],
      },
      messages: [],
      lastMessage: {},
      formData: {
        body: '',
      },
    }
  },

  mounted() {
    var _this = this;

    if (_this.$store.state.role == 'admin') _this.$router.push({ path: '/dashboard/admin' });
    if (_this.$store.state.role == 'professional') _this.$router.push({ path: '/dashboard/professional' });

    axios
      .get('/api/v1/hirings/' + _this.$route.params.hiring, {
        headers: {
          Authorization: 'Bearer ' + _this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          _this.hiring = response.data.data;
          _this.company = _this.hiring.company;
        } else {
          console.log('get hiring', response.data.error);
        }
    });

    axios
      .get('/api/v1/users/' + _this.$route.params.provider, {
        headers: {
          Authorization: 'Bearer ' + _this.TOKEN,
        }
      })
      .then(response => {
        if (!response.data.error) {
          _this.provider = response.data.data;
        } else {
          console.log('get user', response.data.error);
        }
    });

    axios
    .get('/api/v1/conversations?hiring=' + _this.$route.params.hiring, {
      headers: {
        Authorization: 'Bearer ' + _this.TOKEN,
      }
    })
    .then(response => {
      if (!response.data.error) {
        let conversations = response.data.data;
        let index = conversations.map(function(e) { return e.user_two; }).indexOf(_this.$route.params.provider * 1);
        if (index > -1) {
          _this.conversation = conversations[index];
          _this.messages = _this.conversation.messages;
          // scroll
          setTimeout(function(){
            var container = _this.$el.querySelector("#chat-painel");
            container.scrollTop = container.scrollHeight;
            _this.startChatRefresh();
          }, 100);
        } else {
          // creates one
          var data = {
            hiring_id: _this.$route.params.hiring,
            provider_id: _this.$route.params.provider,
          };
          axios
          .post('/api/v1/conversations', data, {
            headers: {
              Authorization: 'Bearer ' + _this.TOKEN,
            }
          })
          .then(response => {
            if (!response.data.error) {
              _this.conversation = response.data.data;
              _this.messages = _this.conversation.messages;
              // scroll
              setTimeout(function(){
                var container = _this.$el.querySelector("#chat-painel");
                container.scrollTop = container.scrollHeight;
                _this.startChatRefresh();
              }, 100);
            } else {
              console.log('post conversation', response.data.error);
            }
          });
        }
      } else {
        console.log('get conversation', response.data.error);
      }
    });

  },

  computed: {
    projectName() {
      return '#' + this.hiring.id + '-' + moment(this.hiring.created_at).format('Y');
    },

    compMessages() {
      return this.messages;
    },
  },

  methods: {
    formatDate(date) {
      return moment(date).format('D/M/YY HH:mm');
    },

    sendMessage() {
      var _this = this;
      var data = _this.formData;
      data.conversation_id = _this.conversation.id;
      axios
        .post('/api/v1/messages', data, {
          headers: {
            Authorization: 'Bearer ' + _this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            _this.messages.push({
              id: response.data.data.id,
              message: response.data.data.message,
              created_at: response.data.data.created_at,
              user_id: _this.me.id,
            });
            _this.formData.body = '';
            // scroll
            setTimeout(function(){
              var container = _this.$el.querySelector("#chat-painel");
              container.scrollTop = container.scrollHeight;
            }, 10);
          } else {
            console.log('post message', response.data.error);
          }
      });
    },

    startChatRefresh() {
      let _this = this;
      setInterval(() => {
        axios
        .get('/api/v1/conversations/' + _this.conversation.id, {
          headers: {
            Authorization: 'Bearer ' + _this.TOKEN,
          }
        })
        .then(response => {
          if (!response.data.error) {
            _this.conversation = response.data.data;
            _this.messages = _this.conversation.messages;
            // scroll
            setTimeout(function() {
              var container = _this.$el.querySelector("#chat-painel");
              container.scrollTop = container.scrollHeight;
            }, 10);
          } else {
            console.log('get conversation', response.data.error);
          }
        });
      }, 10000);
    },
  },

}
